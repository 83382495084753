<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="store-id"
            v-model="filters.storeId"
            :required="true"
            :filter="(store) => store.type === 'Kiosk'"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="id"
            v-model="filters.palmBeerBarrelCode"
            type="number"
            :label="$t('Id do Barril')"
            :placeholder="$t('Id do Barril')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="brand"
            v-model="filters.brandId"
            type="vue-select"
            :label="$t('Marca')"
            :placeholder="$t('Selecione uma marca')"
            :options="getComboBrands"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="brand"
            v-model="filters.consumptionType"
            type="vue-select"
            :label="$t('Tipo de consumo')"
            :placeholder="$t('Todos')"
            :options="[
              { value: 'Administrative', label: $t('Administrador') },
              { value: 'Customer', label: $t('Cliente') },
            ]"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="product-name"
            v-model="filters.skuId"
            :required="false"
          />
        </b-col>
        <b-col md="4">
          <e-person-search
            id="order-customer_id"
            v-model="filters.customerId"
            :label="$t('Cliente')"
            is-customer
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="period"
            v-model="filters.consumptionRangeDate"
            type="date-range-picker"
            :label="$t('Período')"
          />
        </b-col>
      </b-row>
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Exportar')"
          @click="onDownloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="barrels-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="$t('Nenhum consumo encontrado')"
        :fields="fields"
        :items="consumptions"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #custom-foot>
          <tr>
            <th
              colspan="4"
              class="text-right"
            >
              Total
            </th>
            <th class="text-center">
              {{ totalMilliliters | number(0) }}
            </th>
            <th class="text-center">
              {{ totalValue | currency }}
            </th>
            <th />
          </tr>
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EButton,
  ESearchSku,
  EStoreCombo,
  EPersonSearch,
} from '@/views/components'
import { statusTypes } from '@/mixins'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EFilters,
    EButton,
    ESearchSku,
    EStoreCombo,
    EPersonSearch,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
      product: {
        id: null,
        name: '',
      },
    }
  },

  computed: {
    ...mapState('pages/kiosk/consumptions', [
      'filters',
      'sorting',
      'reportData',
      'consumptions',
      'paging',
      'summaryFields',
    ]),
    ...mapGetters('pages/catalog/products', ['getComboBrands']),
    fields() {
      return [
        {
          label: this.$t('#'),
          key: 'palmBeerHistoryCode',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Barril'),
          key: 'barrel.palmBeerBarrelCode',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Produto'),
          key: 'barrel.sku.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Cliente'),
          key: 'customer',
          tdClass: 'text-left',
          sortable: true,
          formatter: (value, index, item) =>
            !item.customer
              ? this.$t('Administrador')
              : `${item.customer.id} - ${item.customer.name}`,
        },
        // {
        //   label: this.$t('Loja'),
        //   key: 'palmBeerProduct.store',
        //   tdClass: 'text-left',
        //   formatter: value => this.$options.filters.storeName(value),
        //   sortable: true,
        // },
        {
          label: this.$t('Quantidade (ML)'),
          key: 'milliliters',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          sortable: true,
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          class: 'text-right',
          thStyle: { width: '80px' },
          formatter: value => `${this.$options.filters.currency(value)}`,
          sortable: true,
        },
        {
          label: this.$t('Data'),
          key: 'consumptionDate',
          class: 'text-center',
          thStyle: { width: '150px' },
          formatter: value => `${this.$options.filters.datetime(value)}`,
          sortable: true,
        },
      ]
    },
    totalMilliliters() {
      return this.summaryFields?.milliliters?.value
    },
    totalValue() {
      return this.summaryFields?.value?.value
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/kiosk/consumptions', [
      'fetchConsumptions',
      'setFilter',
      'generateReport',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    ...mapActions('pages/catalog/products', ['fetchDomainData']),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchConsumptions()
          await this.fetchDomainData('brands')
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    async onDownloadReport() {
      try {
        this.fetching = true
        await this.generateReport()
        // Get the download link element from the template
        const { downloadLink } = this.$refs

        if (this.reportData) {
          // Set the URL and download attributes of the link
          downloadLink.href = this.reportData
          downloadLink.download = `Relatório_Consumo_Quioesque_${moment().format()}.xlsx`

          // Click the link to start the download
          downloadLink.click()
        } else {
          this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style></style>
